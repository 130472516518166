<template>
  <div id="projects-form-create">
    <b-card title="Datos">
      <div class="divInfo" :style="sheet">
        <p class="flex">
          <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
          Campos obligatorios (*)
        </p>
      </div>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Nombre *" label-for="name">
            <b-form-input
              id="name"
              v-model="createForm.project_name"
              name="name"
              placeholder="Ej: Mirador del sur"
              :state="project_nameErrors.length > 0 ? false : null"
              @input="$v.createForm.project_name.$touch()"
            />
            <small class="text-danger">{{ project_nameErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Tipo de propiedad *" label-for="property_type">
            <v-select
              v-model="createForm.property_type"
              label="name"
              placeholder="Selecciona una propiedad"
              :options="property_type"
              :reduce="property_type => property_type.id"
              @input="$v.createForm.property_type.$touch()"
            >
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
            <small class="text-danger">{{ property_typeErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Tipo de subsidio *" label-for="subsidy_type">
            <v-select
              v-model="createForm.subsidy_type"
              label="name"
              multiple
              placeholder="Selecciona un subsidio"
              :options="subsidyTypes"
              :reduce="subsidyTypes => subsidyTypes.id"
              @input="$v.createForm.subsidy_type.$touch()"
            >
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
            <small class="text-danger">{{ subsidy_typeErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <!--
        <b-col cols="12" md="4">
          <b-form-group label="Etapa de venta *" label-for="construction_stage">
            <v-select
              v-model="createForm.project_status_id"
              label="name"
              placeholder="Selecciona una etapa"
              :options="projectStatus"
              :reduce="projectStatus => projectStatus.id"
              @input="$v.createForm.project_status_id.$touch()">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ construction_stageErrors[0] }}</small>
          </b-form-group>
        </b-col>
        -->
        <!--
        <b-col cols="12" md="4">
          <b-form-group label="Etapa de construcción" label-for="property_type">
            <v-select
              v-model="createForm.project_stage"
              label="name"
              placeholder="Selecciona un estado"
              :options="projectStage"
              :reduce="projectStage => projectStage.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        -->
        <b-col cols="12" md="4">
          <b-form-group label="Inmobiliaria *" label-for="construction_stage">
            <v-select
              v-model="createForm.real_state_agency"
              label="name"
              placeholder="Selecciona una inmobiliaria"
              :options="realStateAgencies"
              :reduce="realStateAgencies => realStateAgencies.id"
              @input="$v.createForm.real_state_agency.$touch()"
            >
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
            <small class="text-danger">{{ real_state_agencyErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <!--
        <b-col cols="12" md="4">
          <b-form-group label="Proyectos de interés" label-for="construction_stage">
            <v-select
              v-model="createForm.projects_related"
              label="name"
              multiple
              placeholder="Selecciona uno o mas proyectos"
              :options="projects"
              :reduce="projects => projects.id"
              v-on:input="maxProjects">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
          </b-form-group>
        </b-col>
        -->
        <!--
        <b-col cols="12" md="4">
          <b-form-group label="Resumen proyecto" label-for="summary">
            <b-form-input id="summary"
              v-model="createForm.summary"
              name="summary"
              placeholder="Ej: Un proyecto con mas de 50 deptos" />
          </b-form-group>
        </b-col>
        -->
        <!--
        <b-col cols="12" md="4">
          <b-form-group label="Vigencia de cotización *" label-for="quotation_validity">
            <b-form-input id="quotation_validity"
              v-model="createForm.quotation_validity"
              name="quotation_validity"
              placeholder="Ej: 30"
              :state="quotation_validityErrors.length > 0 ? false:null"
              @input="$v.createForm.quotation_validity.$touch()"/>
            <small class="text-danger">
              {{ quotation_validityErrors[0] }}
            </small>
          </b-form-group>
        </b-col>
        -->
        <!--
        <b-col cols="12" md="4">
          <b-form-group label="Vigencia de reserva *" label-for="reserve_validity">
            <b-form-input id="reserve_validity"
              v-model="createForm.reserve_validity"
              name="reserve_validity"
              placeholder="Ej: 30"
              :state="reserve_validityErrors.length > 0 ? false:null"
              @input="$v.createForm.reserve_validity.$touch()"/>
            <small class="text-danger">
              {{ reserve_validityErrors[0] }}
            </small>
          </b-form-group>
        </b-col>
        -->
        <b-col cols="12" md="4" v-show="show_orden_input">
          <b-form-group label="Orden" label-for="orden">
            <b-form-input
              id="orden"
              v-model="createForm.orden"
              name="orden"
              placeholder="Ej: 1"
              :state="ordenErrors.length > 0 ? false : null"
            />
          </b-form-group>
          <small class="text-danger">
            {{ ordenErrors[0] }}
          </small>
        </b-col>
        <b-col cols="12" md="4" v-if="false">
          <b-form-group label="Tipo de proyecto" label-for="tipo_proyecto">
            <v-select
              v-model="createForm.type"
              label="name"
              placeholder="Selecciona un tipo de proyecto"
              :options="projects_type"
              :reduce="projects_type => projects_type.id"
            >
              <span slot="no-options"> No se encontraron opciones </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="ckeditor ck-editor_editable_inline my-1">
            <p class="mb-1">Descripción del Proyecto</p>
            <ckeditor
              id="editor1"
              :editor="editor"
              v-model="createForm.project_description"
              :config="editorConfig"
            >
            </ckeditor>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Detalles">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="CRM ID" label-for="crm_id">
            <b-form-input
              id="crm_id"
              v-model="createForm.crm_id"
              name="crm_id"
              placeholder="Identificador de CRM"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="CRM Correo" label-for="crm_email">
            <b-form-input
              id="crm_email"
              v-model="createForm.crm_email"
              name="crm_id"
              placeholder="Correo de CRM"
              :state="crm_email_listErrors.length > 0 ? false : null"
              @input="$v.createForm.crm_email.$touch()"
            />
            <small class="text-danger">{{ crm_email_listErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="CRM ZP ID" label-for="crm_zp">
            <b-form-input
              id="crm_zp"
              v-model="createForm.crm_zp"
              name="crm_zp"
              placeholder="Identificador de CRM ZP"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Precio desde *" label-for="price_from">
            <b-form-input
              id="price_from"
              v-model="createForm.price_from"
              name="price_from"
              placeholder="Ej: 1.500"
              :state="price_fromErrors.length > 0 ? false : null"
              @input="$v.createForm.price_from.$touch()"
            />
            <small class="text-danger">{{ price_fromErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <!--
        <b-col cols="12" md="4">
          <b-form-group label="Baños desde *" label-for="bathrooms_from">
            <b-form-input id="bathrooms_from"
              v-model="createForm.bathrooms_from"
              name="bathrooms_from"
              placeholder="Ej: 1 y 2"
              :state="bathrooms_fromErrors.length > 0 ? false:null"
              @input="$v.createForm.bathrooms_from.$touch()"/>
            <small class="text-danger">{{ bathrooms_fromErrors[0] }}</small>
          </b-form-group>
        </b-col>
        -->
        <b-col cols="12" md="4">
          <b-form-group label="Dormitorios desde *" label-for="bedrooms_from">
            <b-form-input
              id="bedrooms_from"
              v-model="createForm.bedrooms_from"
              name="bedrooms_from"
              placeholder="Ej: 1 , 2 y 3"
              :state="bedrooms_fromErrors.length > 0 ? false : null"
              @input="$v.createForm.bedrooms_from.$touch()"
            />
            <small class="text-danger">{{ bedrooms_fromErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Área desde *" label-for="area_from">
            <b-form-input
              id="area_from"
              v-model="createForm.area_from"
              name="area_from"
              placeholder="Ej: 60 a 100"
              :state="area_fromErrors.length > 0 ? false : null"
              @input="$v.createForm.area_from.$touch()"
            />
            <small class="text-danger">{{ area_fromErrors[0] }}</small>
          </b-form-group>
        </b-col>

        <b-col
          v-for="atraction in atractions"
          :key="atraction.name_en"
          cols="12"
          md="3"
        >
          <b-form-checkbox
            :id="atraction.name_en"
            v-model="createForm[atraction.name_en]"
            :name="atraction.name_en"
            :value="true"
            :unchecked-value="false"
          >
            {{ atraction.name_es }}
          </b-form-checkbox>
        </b-col>

        <!--
        <b-col cols="12" md="4">
          <b-form-group label="Fecha entrega" label-for="deliver_date">
            <b-form-input id="deliver_date"
              v-model="createForm.deliver_date"
              name="deliver_date"
              placeholder="Ej: Primer Semestre 2023"/>
          </b-form-group>
        </b-col>
        -->
        <!--
        <b-col cols="12">
          <label for="legal">
            Legal Cotizaciones
          </label>
          <b-form-textarea
            v-model="createForm.legal_quote_text"
            id="legal"
            placeholder="Texto legal de cotizaciones"
            rows="3"/>
        </b-col>
        -->
      </b-row>
    </b-card>

    <b-card title="Características Ventas">
      <b-row>
        <b-col xl="4" sm="12">
          <b-form-group label="Api key" label-for="api_key">
            <b-input-group>
              <b-form-input
                id="api_key"
                v-model="createForm.api_key"
                name="api_key"
                :type="passwordFieldTypeApiKey"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIconApiKey"
                  @click="togglePasswordVisibilityApiKey()"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col xl="4" sm="12">
          <b-form-group label="Secret key" label-for="secret_key">
            <b-input-group>
              <b-form-input
                id="secret_key"
                v-model="createForm.secret_key"
                name="secret_key"
                :type="passwordFieldTypeSecretKey"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIconSecretKey"
                  @click="togglePasswordVisibilitySecretKey()"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--
        <b-col xl="4" sm="12">
          <b-form-group label="Tipo de Promesa *" label-for="reserve_type">
            <v-select
              v-model="createForm.promises_type"
              label="name"
              placeholder="Selecciona una opción"
              :options="reserve_type"
              :reduce="reserve_type => reserve_type.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ promises_typeErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="4" sm="12">
          <b-form-group label="Tipo de Reserva *" label-for="reserve_type">
            <v-select
                v-model="createForm.reserve_type"
                label="name"
                placeholder="Selecciona una opción"
                :options="reserve_type"
                :reduce="reserve_type => reserve_type.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ reserve_typeErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="4" sm="12">
          <b-form-group label="Moneda *" label-for="money_type">
            <v-select
              :clearable="false"
              v-model="createForm.money_type"
              label="name"
              placeholder="Selecciona una opción"
              :options="money_type"
              :reduce="money_type => money_type.id">
              <span slot="no-options">
                No se encontraron opciones
              </span>
            </v-select>
            <small class="text-danger">{{ money_typeErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col xl="4" sm="12">
          <b-form-group label="Monto *" label-for="reserve_amount">
            <b-form-input id="reserve_amount"
              v-model="createForm.reserve_amount"
              name="reserve_amount"
              placeholder="Ej: 2000"
              :state="reserve_amountErrors.length > 0 ? false:null"
              @input="$v.createForm.reserve_amount.$touch()"/>
            <small class="text-danger">{{ reserve_amountErrors[0] }}</small>
          </b-form-group>
        </b-col>
        -->
      </b-row>
    </b-card>

    <b-card title="Ubicación">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Puedes ingresar la dirección en el casillero Dirección del proyecto
            y elegirla de la lista desplegable que aparecerá o puede mover el
            marcador en el mapa hasta el lugar donde está ubicado el proyecto
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="3">
            <b-form-group label="Dirección *" label-for="autocomplete">
              <input
                class="form-control"
                v-model="createForm.address"
                name="address"
                ref="autocomplete"
                id="autocomplete"
                placeholder="Dirección del proyecto"
              />
              <small class="text-danger">{{ addressErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Región *" label-for="region">
              <v-select
                v-model="createForm.region"
                label="name"
                placeholder="Selecciona una región"
                :options="locations"
                :reduce="region => region.id"
                v-on:input="checkRegion"
                @input="$v.createForm.region.$touch()"
              >
                <span slot="no-options"> No se encontraron opciones </span>
              </v-select>
              <small class="text-danger">{{ regionErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Provincia *" label-for="province">
              <v-select
                :disabled="
                  createForm.region === '' || createForm.region === null
                "
                v-model="createForm.province"
                label="name"
                placeholder="Selecciona una provincia"
                :options="provinces_list"
                :reduce="province => province.id"
                v-on:input="checkProvince"
                @input="$v.createForm.province.$touch()"
              >
                <span slot="no-options"> No se encontraron opciones </span>
              </v-select>
              <small class="text-danger">{{ provinceErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Comuna *" label-for="commune">
              <v-select
                :disabled="
                  createForm.province === '' || createForm.province === null
                "
                v-model="createForm.commune"
                label="name"
                placeholder="Selecciona una comuna"
                :options="communes_list"
                :reduce="commune => commune.id"
                @input="$v.createForm.commune.$touch()"
              >
                <span slot="no-options"> No se encontraron opciones </span>
              </v-select>
              <small class="text-danger">{{ communeErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12" md="6">
            <b-form-group label="Latitud *" label-for="latitude">
              <b-form-input
                id="latitude"
                v-model="createForm.latitude"
                name="latitude"
                placeholder="Coordenada latitud"
                :state="latitudeErrors.length > 0 ? false : null"
                @input="$v.createForm.latitude.$touch()"
              />
              <small class="text-danger">{{ latitudeErrors[0] }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Longitud *" label-for="longitude">
              <b-form-input
                id="Longitud"
                v-model="createForm.longitude"
                name="longitude"
                placeholder="Coordenada longitud"
                :state="longitudeErrors.length > 0 ? false : null"
                @input="$v.createForm.longitude.$touch()"
              />
              <small class="text-danger">{{ longitudeErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <gmaps-map
              :options="mapOptions"
              @mounted="ready"
              style="width: 100%; height: 500px"
            >
              <gmaps-marker :options="markerOptions" @move="updatePosition" />
            </gmaps-map>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card title="Restricciones">
      <div>
        <b-row class="my-1">
          <b-col vs-type="flex" cols="12" md="4">
            <b-form-group label="Vende subsidios" label-for="sell_subsidies">
              <v-select
                v-model="createForm.sell_subsidies"
                label="name"
                placeholder="Selecciona una opción"
                :options="answers"
                :reduce="answers => answers.id"
              >
                <span slot="no-options"> No se encontraron opciones </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col vs-type="flex" cols="12" md="4">
            <b-form-group
              label="Planes adicionales"
              label-for="plans_additional"
            >
              <v-select
                v-model="createForm.plans_additional"
                label="name"
                multiple
                placeholder="Selecciona el plan adicional"
                :options="plansAdditional"
                :reduce="plansAdditional => plansAdditional.id"
              >
                <span slot="no-options"> No se encontraron opciones </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col vs-type="flex" cols="12" md="4">
            <b-form-group label="Chatbot URL" label-for="chatbot_url">
              <b-form-input
                id="chatbot_url"
                v-model="createForm.chatbot_url"
                name="chatbot_url"
                :state="chatbotURLErrors.length > 0 ? false : null"
                @input="$v.createForm.chatbot_url.$touch()"
              />
              <small class="text-danger">{{ chatbotURLErrors[0] }}</small>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card title="Medias">
      <b-row class="my-1">
        <b-col vs-type="flex" cols="12" md="4">
          <b-form-group label="Brochure" label-for="brochure">
            <b-form-input
              id="brochure"
              v-model="createForm.brochure"
              name="brochure"
              placeholder="Ej: http://www.ejemplo.cl"
              :state="brochureErrors.length > 0 ? false : null"
              @input="$v.createForm.brochure.$touch()"
            />
            <small class="text-danger">{{ brochureErrors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col vs-type="flex" cols="12" md="4">
          <b-form-group label="Nube 360" label-for="nube360">
            <b-form-input
              id="nube360"
              v-model="createForm.nube360"
              name="nube360"
              placeholder="Ej: http://www.ejemplo.cl"
              :state="nube360Errors.length > 0 ? false : null"
              @input="$v.createForm.nube360.$touch()"
            />
            <small class="text-danger">{{ nube360Errors[0] }}</small>
          </b-form-group>
        </b-col>
        <b-col vs-type="flex" cols="12" md="4">
          <b-form-group label="Tour 360" label-for="tour360">
            <b-form-input
              id="tour360"
              v-model="createForm.tour360"
              name="tour360"
              placeholder="Ej: http://www.ejemplo.cl"
              :state="tour360Errors.length > 0 ? false : null"
              @input="$v.createForm.tour360.$touch()"
            />
            <small class="text-danger">{{ tour360Errors[0] }}</small>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="title-contactos-ejecutivos">
      <b-row>
        <b-col cols="6"><h4 class="card-title">Contacto ejecutivo</h4></b-col>
        <b-col cols="6"
          ><b-button
            variant="primary"
            @click="addContactOrder()"
            class="float-right"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />Agregar
            ejecutivo</b-button
          ></b-col
        >
        <b-col cols="12">
          <div class="divInfo" :style="sheet">
            <p class="flex">
              <feather-icon
                size="25"
                icon="AlertCircleIcon"
                class="mx-50 my-1"
              />Ingresa el nombre, imagen, email y teléfono del contacto
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col
          ols="12"
          sm="4"
          lg="3"
          v-for="(contact_order, indexC) in contacts_orders"
          :key="indexC"
        >
          <div>
            <img
              class="image aca123"
              :src="
                contact_order.image != undefined
                  ? contact_order.image.full_url
                  : logoUserDefault
              "
            />
          </div>
          <div class="mt-2">
            <feather-icon size="16" icon="UserIcon" class="mr-50" />{{
              contact_order.name
            }}
          </div>
          <div>
            <feather-icon size="16" icon="PhoneIcon" class="mr-50" />{{
              contact_order.phone
            }}
          </div>
          <div class="mb-2">
            <feather-icon size="16" icon="MailIcon" class="mr-50" />{{
              contact_order.email
            }}
          </div>
          <b-row>
            <b-col cols="6">
              <b-button
                variant="primary"
                @click="showModalEdit(contact_order, indexC)"
                >Editar</b-button
              >
            </b-col>
            <b-col cols="6">
              <b-button
                variant="primary"
                class="float-right"
                @click="deleteContactOrder(contact_order, indexC)"
                >Eliminar</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div class="danger-text" v-if="$v.createForm.executive_name_list.$error">
        {{ executive_name_listErrors[0] }}
      </div>
    </b-card>
    <b-card title="Contacto ejecutivo" class="d-none">
      <div class="divInfo" :style="sheet">
        <p class="flex">
          <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
          Ingresa un nombre, teléfono o email, has click en el cualquiera de los
          datos ingresados para eliminarlo
        </p>
      </div>
      <b-row class="my-1">
        <b-col cols="12" md="4">
          <b-input-group>
            <b-form-input
              id="executive_name"
              v-model="executive_name"
              name="executive_name"
              placeholder="Nombre de ejecutivo"
              :state="executive_nameErrors.length > 0 ? false : null"
              @input="$v.executive_name.$touch()"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="_addExecutive('name')">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Agregar
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ executive_nameErrors[0] }}</small>
        </b-col>
        <b-col cols="12" md="4">
          <b-input-group>
            <b-form-input
              id="executive_phone"
              v-model="executive_phone"
              name="executive_phone"
              placeholder="Teléfono de ejecutivo"
              :state="executive_phoneErrors.length > 0 ? false : null"
              @input="$v.executive_phone.$touch()"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="_addExecutive('phone')">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Agregar
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ executive_phoneErrors[0] }}</small>
        </b-col>
        <b-col cols="12" md="4">
          <b-input-group>
            <b-form-input
              id="executive_email"
              v-model="executive_email"
              name="executive_email"
              placeholder="Email de ejecutivo"
              :state="executive_emailErrors.length > 0 ? false : null"
              @input="$v.executive_email.$touch()"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="_addExecutive('email')">
                <feather-icon icon="PlusIcon" class="mr-50" />
                Agregar
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ executive_emailErrors[0] }}</small>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div
            class="danger-text"
            v-if="$v.createForm.executive_name_list.$error"
          >
            {{ executive_name_listErrors[0] }}
          </div>
          <h4>
            <b-button
              size="sm"
              class="mr-1 mb-1"
              href="#"
              variant="primary"
              @click="_removeExecutive(name, 'name')"
              v-for="name in createForm.executive_name_list"
              :key="name"
              closable
            >
              <span class="align-middle">
                {{ name }}
              </span>
              <feather-icon icon="XIcon" class="mr-50" />
            </b-button>
          </h4>
        </b-col>
        <b-col>
          <div
            class="danger-text"
            v-if="$v.createForm.executive_phone_list.$error"
          >
            {{ executive_phone_listErrors[0] }}
          </div>
          <h4>
            <b-button
              size="sm"
              class="mr-1 mb-1"
              href="#"
              variant="primary"
              @click="_removeExecutive(phone, 'phone')"
              v-for="phone in createForm.executive_phone_list"
              :key="phone"
              closable
            >
              <span class="align-middle">
                {{ phone }}
              </span>
              <feather-icon icon="XIcon" class="mr-50" />
            </b-button>
          </h4>
        </b-col>
        <b-col>
          <div
            class="danger-text"
            v-if="$v.createForm.executive_email_list.$error"
          >
            {{ executive_email_listErrors[0] }}
          </div>
          <h4>
            <b-button
              size="sm"
              class="mr-1 mb-1"
              href="#"
              variant="primary"
              @click="_removeExecutive(email, 'email')"
              v-for="email in createForm.executive_email_list"
              :key="email"
              closable
            >
              <span class="align-middle">
                {{ email }}
              </span>
              <feather-icon icon="XIcon" class="mr-50" />
            </b-button>
          </h4>
        </b-col>
      </b-row>

      <b-row class="my-1">
        <b-col cols="12">
          <b-form-file
            v-model="gallery_contacts"
            accept=".jpeg, .jpg, .png"
            browse-text="Formatos: jpeg - jpg - png"
            multiple
            placeholder="Selecciona o arrastra varias imágenes"
            drop-placeholder="Arrastra aquí la imagen"
            @change="_previewFileGalleryContact"
          />

          <b-row class="mt-2">
            <b-col
              v-if="this.createForm.gallery_contacts.length === 0"
              cols="12"
            >
              <div class="preview">
                <h5>Previsualización Contacto</h5>
              </div>
            </b-col>
            <b-col
              v-else
              cols="12"
              sm="4"
              lg="3"
              v-for="(img, i) in this.createForm.gallery_contacts"
              :key="i"
            >
              <div class="container">
                <img class="image" :src="img != undefined ? img.src : ''" />
                <div class="mt-1 mb-1" align="center">
                  <b-button @click="_deleteGalleryContact(i)" variant="primary">
                    <feather-icon icon="SaveIcon" class="mr-50" />
                    <span class="align-middle"> Eliminar </span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <!--
    <b-card title="Receptor de desistimiento">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon
              size="25"
              icon="AlertCircleIcon"
              class="mx-50 my-1"/>
            Puedes ingresar un receptor o varios, has click en un correo ingresado para eliminarlo
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="4">
            <b-input-group>
              <b-form-input id="email_disengagement"
                v-model="email_disengagement"
                name="email_disengagement"
                placeholder="Email de receptor(es)"
                :state="email_disengagementErrors.length > 0 ? false:null"
                @input="$v.email_disengagement.$touch()"/>
                <b-input-group-append>
                    <b-button variant="primary" @click="_addEmail('disengagement')">
                      <feather-icon
                      icon="PlusIcon"
                      class="mr-50"/>
                      Agregar
                    </b-button>
                </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ email_disengagementErrors[0] }}</small>
          </b-col>
        </b-row>
        <b-row>
          <div class="danger-text mx-1" v-if="$v.createForm.email_disengagement_list.$error">{{email_disengagement_listErrors[0]}}</div>
          <h4>
            <b-button size="sm" class="mx-1" href="#" variant="primary" @click="_removeEmail(email, 'disengagement')" v-for="email in createForm.email_disengagement_list" :key="email" closable>
            <span class="align-middle">
              {{ email }}
            </span>
              <feather-icon
              icon="XIcon"
              class="mr-50"/>
            </b-button>
          </h4>
        </b-row>
      </div>
    </b-card>
    -->
    <b-card title="Receptor de cotizaciones">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Puedes ingresar un receptor o varios, has click en un correo
            ingresado para eliminarlo
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="4">
            <b-input-group>
              <b-form-input
                id="email_quotation"
                v-model="email_quotation"
                name="email_quotation"
                placeholder="Email de receptor(es)"
                :state="email_quotationErrors.length > 0 ? false : null"
                @input="$v.email_quotation.$touch()"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="_addEmail('quotation')">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  Agregar
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ email_quotationErrors[0] }}</small>
          </b-col>
        </b-row>
        <b-row>
          <div
            class="danger-text mx-1"
            v-if="$v.createForm.email_quotation_list.$error"
          >
            {{ email_quotation_listErrors[0] }}
          </div>
          <h4>
            <b-button
              size="sm"
              class="mx-1"
              href="#"
              variant="primary"
              @click="_removeEmail(email, 'quotation')"
              v-for="email in createForm.email_quotation_list"
              :key="email"
              closable
            >
              <span class="align-middle">
                {{ email }}
              </span>
              <feather-icon icon="XIcon" class="mr-50" />
            </b-button>
          </h4>
        </b-row>
      </div>
    </b-card>

    <b-card title="Receptor de reservas">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Puedes ingresar un receptor o varios, has click en un correo
            ingresado para eliminarlo
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="4">
            <b-input-group>
              <b-form-input
                id="email_booking"
                v-model="email_booking"
                name="email_booking"
                placeholder="Email de receptor(es)"
                :state="email_bookingErrors.length > 0 ? false : null"
                @input="$v.email_booking.$touch()"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="_addEmail('booking')">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  Agregar
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ email_bookingErrors[0] }}</small>
          </b-col>
        </b-row>
        <b-row>
          <div
            class="danger-text mx-1"
            v-if="$v.createForm.email_booking_list.$error"
          >
            {{ email_booking_listErrors[0] }}
          </div>
          <h4>
            <b-button
              size="sm"
              class="mx-1"
              href="#"
              variant="primary"
              @click="_removeEmail(email, 'booking')"
              v-for="email in createForm.email_booking_list"
              :key="email"
              closable
            >
              <span class="align-middle">
                {{ email }}
              </span>
              <feather-icon icon="XIcon" class="mr-50" />
            </b-button>
          </h4>
        </b-row>
      </div>
    </b-card>

    <!--
    <b-card title="Receptor de promesas">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon
              size="25"
              icon="AlertCircleIcon"
              class="mx-50 my-1"/>
            Puedes ingresar un receptor o varios, has click en un correo ingresado para eliminarlo
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="4">
            <b-input-group>
              <b-form-input id="email_promise"
                v-model="email_promise"
                name="email_promise"
                placeholder="Email de receptor(es)"
                :state="email_promiseErrors.length > 0 ? false:null"
                @input="$v.email_promise.$touch()"/>
              <b-input-group-append>
                  <b-button variant="primary" @click="_addEmail('promise')">
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"/>
                    Agregar
                  </b-button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ email_promiseErrors[0] }}</small>
          </b-col>
        </b-row>
        <b-row>
          <div class="danger-text mx-1" v-if="$v.createForm.email_promise_list.$error">{{email_promise_listErrors[0]}}</div>
          <h4>
            <b-button size="sm" class="mx-1" href="#" variant="primary" @click="_removeEmail(email, 'promise')" v-for="email in createForm.email_promise_list" :key="email" closable>
            <span class="align-middle">
              {{ email }}
            </span>
              <feather-icon
              icon="XIcon"
              class="mr-50"/>
            </b-button>
          </h4>
        </b-row>
      </div>
    </b-card>
    -->
    <b-card title="Fotografías de presentación">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Estas imágenes son las que se utilizarán como miniatura del proyecto
            y como imagen de portada
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12" md="6">
            <b-form-file
              v-model="miniature"
              accept=".jpeg, .jpg, .png"
              browse-text="Formatos: jpeg - jpg - png"
              placeholder="Selecciona o arrastra una imagen"
              drop-placeholder="Arrastra aquí la imagen"
              @change="_previewFileMiniature"
            />
            <small class="text-danger">{{ miniatureErrors[0] }}</small>
            <b-row class="mt-2">
              <b-col v-if="createForm.miniature === ''" cols="12">
                <div class="preview">
                  <h5>Previsualización Miniatura</h5>
                  <h5><strong>1366x768</strong></h5>
                </div>
              </b-col>
              <b-col v-else cols="12">
                <div class="container" width="300">
                  <img class="image" :src="createForm.miniature.src" />
                </div>
              </b-col>
              <b-col
                v-if="createForm.miniature != ''"
                cols="12"
                class="mt-1"
                align="center"
              >
                <b-button variant="primary" @click="_deleteMiniature()">
                  <feather-icon icon="SaveIcon" class="mr-50" />
                  <span class="align-middle"> Eliminar </span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!--
          <b-col cols="12" md="6">
            <b-form-file
              v-model="portrait"
              accept=".jpeg, .jpg, .png"
              browse-text="Formatos: jpeg - jpg - png"
              placeholder="Selecciona o arrastra una imagen"
              drop-placeholder="Arrastra aquí la imagen"
              @change="_previewFilePortrait" />
            <small class="text-danger">{{ portraitErrors[0] }}</small>
            <b-row class="mt-2">
              <b-col v-if="createForm.portrait === ''" cols="12">
                <div class="preview">
                  <h5>Previsualización Portada</h5>
                  <h5><strong>1366x768</strong></h5>
                </div>
              </b-col>
              <b-col v-else cols="12">
                <div class="container" width="300">
                  <img class="image" :src="createForm.portrait.src">
                </div>
              </b-col>
              <b-col v-if="createForm.portrait != ''" cols="12" class="mt-1" align="center">
                <b-button variant="primary" @click="_deletePortrait()">
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"/>
                <span class="align-middle">
                  Eliminar
                </span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          -->
        </b-row>
      </div>
    </b-card>
    <b-card title="Fotografías de galería">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Adjunta alguna(s) imágen(es) del proyecto para poder mostrarlas en
            la galería del proyecto
          </p>
        </div>
        <b-row class="my-1">
          <b-col cols="12">
            <b-form-file
              v-model="gallery"
              accept=".jpeg, .jpg, .png"
              browse-text="Formatos: jpeg - jpg - png"
              multiple
              placeholder="Selecciona o arrastra varias imágenes"
              drop-placeholder="Arrastra aquí la imagen"
              @change="_previewFileGallery"
            />
            <small class="text-danger">{{ galleryErrors[0] }}</small>
            <b-row class="mt-2">
              <b-col v-if="this.createForm.gallery.length === 0" cols="12">
                <div class="preview">
                  <h5>Previsualización Galería</h5>
                  <h5><strong>800x392</strong></h5>
                </div>
              </b-col>
              <b-col
                v-else
                cols="12"
                sm="4"
                lg="3"
                v-for="(img, i) in this.createForm.gallery"
                :key="i"
              >
                <div class="container">
                  <img class="image" :src="img.src" />
                  <div class="mt-1 mb-3" align="center">
                    <b-button @click="_deleteGallery(i)" variant="primary">
                      <feather-icon icon="SaveIcon" class="mr-50" />
                      <span class="align-middle"> Eliminar </span>
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card title="Cargar unidades">
      <div>
        <div class="divInfo" :style="sheet">
          <p class="flex">
            <feather-icon size="25" icon="AlertCircleIcon" class="mx-50 my-1" />
            Adjunta un excel con las unidades disponibles, este excel debe tener
            el formato que se presenta en el ejemplo para descarga. Cargar las
            unidades es de carácter opcional
          </p>
        </div>
        <b-row class="px-1">
          <b-button
            class="my-1"
            variant="primary"
            @click="_exportDemoProperties()"
          >
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span class="align-middle"> Descargar Ejemplo </span>
          </b-button>
        </b-row>
        <b-row class="my-1">
          <b-col class="my-1" vs-w="12">
            <input
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              style="display: none"
              id="excelInput"
              type="file"
              @change="_previewFileExcel"
            />
            <label class="inputLabel" for="excelInput"
              >Selecciona un archivo</label
            >
            <!-- <small class="text-danger">{{ excelErrors[0] }}</small> -->
            <b-row class="mt-2">
              <b-col v-if="this.createForm.excel.length === 0" vs-w="3">
                <div class="preview">
                  <h5>Archivo</h5>
                </div>
              </b-col>
              <b-col v-else vs-w="3">
                <div class="container">
                  <div class="preview">
                    <h5 class="mt-12">{{ this.createForm.excel.name }}</h5>
                  </div>
                </div>
              </b-col>
              <b-col
                v-if="this.createForm.excel.length > 0"
                cols="12"
                class="mt-1"
                align="center"
              >
                <b-button variant="primary" @click="_deleteExcel()"
                  >Eliminar</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-modal
          id="excel-modal"
          ok-only
          centered
          button-size="md"
          ok-title="Aceptar"
          no-close-on-backdrop
          no-close-on-esc
          title="Corregir los siguientes campos y subir nuevamente el archivo Excel"
        >
          <div v-for="(data, x) in this.infoExcelProperty" :key="x">
            <h3>Identificador: {{ data.identifier }}</h3>
            <div v-if="!data.empty">
              <div v-for="(msg, y) in data.messages" :key="y">
                <h6 style="color: red">- {{ msg }}</h6>
              </div>
            </div>
            <div v-else>
              <h6 style="color: green">- No hay correciones para esta fila</h6>
            </div>
            <br />
          </div>
        </b-modal>
      </div>
    </b-card>
    <b-row align-h="end">
      <div class="d-flex align-items-end justify-content-end mr-1 mt-1 mb-2">
        <div>
          <b-overlay
            :show="overlayForm"
            opacity="0.5"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
            @hidden="_onHidden()"
          >
            <b-button
              ref="button"
              :disabled="overlayForm"
              variant="primary"
              @click.prevent="_createProjects()"
            >
              <feather-icon icon="SaveIcon" class="mr-50" />
              <span class="align-middle"> Guardar </span>
            </b-button>
          </b-overlay>
        </div>
      </div>
    </b-row>
    <b-modal
      hide-footer
      ref="modalContacts"
      title="Contactos"
      @hide="modalClosed"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <div>
              <b-form-group label="Nombre">
                <b-form-input
                  v-model="$v.modalContact.name.$model"
                  placeholder="Nombre"
                />
                <div v-if="$v.modalContact.name.$dirty">
                  <small
                    class="text-danger"
                    v-if="!$v.modalContact.name.required"
                    >Campo necesario</small
                  >
                </div>
              </b-form-group>
            </div>
            <div>
              <b-form-group label="Telefono">
                <b-form-input
                  v-model="$v.modalContact.phone.$model"
                  placeholder="Telefono"
                />
                <div v-if="$v.modalContact.phone.$dirty">
                  <small
                    class="text-danger"
                    v-if="!$v.modalContact.phone.required"
                    >Campo necesario</small
                  >
                  <small
                    class="text-danger"
                    v-if="
                      $v.modalContact.phone.required &&
                      !$v.modalContact.phone.numeric
                    "
                    >Solo numeros</small
                  >
                  <small
                    class="text-danger"
                    v-if="
                      $v.modalContact.phone.required &&
                      $v.modalContact.phone.numeric &&
                      !$v.modalContact.phone.minLength
                    "
                    >Debes ingresar 9 dígitos</small
                  >
                  <small
                    class="text-danger"
                    v-if="
                      $v.modalContact.phone.required &&
                      $v.modalContact.phone.numeric &&
                      $v.modalContact.phone.minLength &&
                      !$v.modalContact.phone.maxLength
                    "
                    >Debes ingresar 9 dígitos</small
                  >
                </div>
              </b-form-group>
            </div>
            <div>
              <b-form-group label="Email">
                <b-form-input
                  v-model="$v.modalContact.email.$model"
                  placeholder="Email"
                />
                <div v-if="$v.modalContact.email.$dirty">
                  <small
                    class="text-danger"
                    v-if="!$v.modalContact.email.required"
                    >Campo necesario</small
                  >
                  <small class="text-danger" v-if="!$v.modalContact.email.email"
                    >Formato de email incorrecto</small
                  >
                </div>
              </b-form-group>
            </div>
            <div>
              <b-col cols="12" sm="12" lg="12">
                <div class="container">
                  <img
                    class="image imageModalImg"
                    :src="
                      modalContact.image != undefined
                        ? modalContact.image.full_url
                        : logoUserDefault
                    "
                  />
                  <div class="mt-1 mb-1" align="center">
                    <b-button
                      @click="_deleteGalleryContactModal()"
                      variant="primary"
                      v-if="
                        modalContact.image != undefined &&
                        modalContact.image != null
                      "
                    >
                      <feather-icon icon="SaveIcon" class="mr-50" />
                      <span class="align-middle">Eliminar</span></b-button
                    >
                  </div>
                </div>
              </b-col>
              <b-form-file
                v-model="gallery_contacts"
                accept=".jpeg, .jpg, .png"
                browse-text="Formatos: jpeg - jpg - png"
                placeholder="Selecciona o arrastra una imagen"
                drop-placeholder="Arrastra aquí la imagen"
                @change="_previewFileGalleryContactModal"
              ></b-form-file>
            </div>
          </b-col>

          <b-col cols="12" class="mt-3">
            <b-button
              variant="primary"
              @click="saveContactOrder()"
              v-if="modalContact.isnew == '1'"
              class="float-right"
              :disabled="$v.modalContact.$invalid"
              >Guardar</b-button
            >
            <b-button
              variant="primary"
              @click="editContactOrderModal()"
              v-else
              class="float-right"
              :disabled="$v.modalContact.$invalid"
              >Guardar</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import * as rutHelpers from 'rut-helpers'
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  required,
  numeric,
  email,
  minLength,
  maxLength,
  url,
  decimal,
  requiredIf
} from 'vuelidate/lib/validators'
import { gmaps, gmapsMap, gmapsMarker } from 'x5-gmaps'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

const isRutValid = value => rutHelpers.rutValidate(value)

export default {
  name: 'ProjectsFormCreate',
  components: { gmapsMap, gmapsMarker },
  data () {
    return {
      logoUserDefault: require('@/assets/images/avatars/default.png'),
      permissions_visible: {
        project_edit_type: false
      },
      show_orden_input: false,
      passwordFieldTypeApiKey: 'password',
      passwordFieldTypeSecretKey: 'password',
      overlayForm: false,
      markerOptions: {
        position: { lat: -33.45694, lng: -70.64827 },
        title: 'Marker A',
        draggable: true
      },
      mapOptions: {
        center: { lat: -33.45694, lng: -70.64827 },
        zoom: 15
      },
      markers: [],
      sheet: {
        backgroundColor: '#EFEFEF'
      },
      infoExcelProperty: [],
      projects_type: [
        { id: 0, name: 'Interno' },
        { id: 1, name: 'Externo' }
      ],
      answers: [
        { id: 0, name: 'No' },
        { id: 1, name: 'Si' }
      ],
      createForm: {
        type: 0,
        project_name: '',
        orden: '',
        property_type: '',
        subsidy_type: [],
        //project_status_id: '',
        real_state_agency: '',
        //projects_related: [],
        //quotation_validity: '',
        //reserve_validity: '',
        crm_id: '',
        crm_zp: '',
        crm_email: '',
        price_from: '',
        bedrooms_from: '',
        //bathrooms_from: '',
        area_from: '',
        //deliver_date: '',
        address: '',
        region: '',
        province: '',
        commune: '',
        latitude: '',
        longitude: '',
        length: '',
        brochure: '',
        nube360: '',
        tour360: '',
        api_key: '',
        secret_key: '',
        //money_type: '',
        //reserve_amount: '',
        //reserve_type: '',
        //promises_type: '',
        //legal_quote_text: '',
        executive_name_list: [],
        executive_phone_list: [],
        executive_email_list: [],
        executive_images: [],
        email_query_list: [],
        email_quotation_list: [],
        //email_promise_list: [],
        email_booking_list: [],
        //email_disengagement_list: [],
        miniature: '',
        //portrait: '',
        gallery: [],
        gallery_saved: [],
        gallery_contacts: [],
        gallery_contacts_saved: [],
        excel: [],
        //summary: '',
        sell_subsidies: 0,
        plans_additional: [],
        //project_stage:0,
        chatbot_url: ''
      },
      modalContact: {
        name: '',
        phone: '',
        email: '',
        image: '',
        isnew: '1',
        realIndex: '-1'
      },
      contacts_orders: [],
      region: '',
      province: '',
      miniature: [],
      //portrait: [],
      gallery: [],
      gallery_contacts: [],
      gallery_contacts_modal: [],
      excel: [],
      provinces_list: [],
      communes_list: [],
      property_type: [
        { id: 'house', name: 'Casa' },
        { id: 'department', name: 'Departamento' }
      ],
      /*money_type: [
          {id: "UF", name:"UF"},
          {id: "CLP", name:"CLP"}
        ],*/
      /*reserve_type: [
          {id:"Direct", name:"Directo"},
          {id:"Request", name:"Por Solicitud"}
        ],*/
      executive_name: '',
      executive_phone: '',
      executive_email: '',
      email_query: '',
      email_quotation: '',
      //email_promise: "",
      email_booking: '',
      //email_disengagement: "",
      editor: ClassicEditor,
      project_description: '',
      editorConfig: {
        toolbar: ['bold', 'italic', '|', 'undo', 'redo'],
        height: '100px'
      },
      jsonExcel: '',
      atractions: [
        { name_en: 'exercise_area', name_es: 'Área de ejercicios' },
        { name_en: 'green_area', name_es: 'Áreas verdes' },
        { name_en: 'bikers', name_es: 'Bicicleteros' },
        { name_en: 'games_accessibility', name_es: 'Juegos infantiles' },
        { name_en: 'calisthenics_area', name_es: 'Área calistenia' },
        { name_en: 'stores', name_es: 'Locales comerciales' },
        { name_en: 'events_room', name_es: 'Sala multiuso' },
        { name_en: 'clean_points', name_es: 'Puntos limpios' },
        { name_en: 'elevators', name_es: 'Ascensores' },
        { name_en: 'controlled_access', name_es: 'Acceso controlado' },
        { name_en: 'quincho', name_es: 'Quincho' },
        { name_en: 'swimming_pool', name_es: 'Piscina' },
        { name_en: 'hospital', name_es: 'Cercano a Hospitales' },
        { name_en: 'subway_station', name_es: 'A pasos de estación de metro' }
      ]
    }
  },
  mixins: [togglePasswordVisibility],
  validations: {
    modalContact: {
      name: { required },
      email: {
        required,
        email
      },
      phone: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(9)
      }
    },
    createForm: {
      project_name: {
        required
      },
      property_type: {
        required
      },
      subsidy_type: {
        required
      },
      /*project_status_id: {
          required,
        },*/
      real_state_agency: {
        required
      },
      /*quotation_validity: {
          required,
          numeric
        },*/
      /*reserve_validity: {
          required,
          numeric
        },*/
      price_from: {
        required
      },
      bedrooms_from: {
        required
      },
      /*bathrooms_from: {
          required,
        },*/
      area_from: {
        required
      },
      region: {
        required
      },
      province: {
        required
      },
      commune: {
        required
      },
      address: {
        required
      },
      latitude: {
        required,
        decimal
      },
      longitude: {
        required,
        decimal
      },
      brochure: {
        url
      },
      nube360: {
        url
      },
      tour360: {
        url
      },
      /*reserve_amount: {
          required,
          numeric
        },*/
      /*money_type: {
          required
        },*/
      /*reserve_type: {
          required,
        },*/
      /*promises_type: {
          required,
        },*/
      executive_name_list: {
        required
      },
      executive_phone_list: {
        required
      },
      executive_email_list: {
        required
      },
      email_quotation_list: {
        required
      },
      /*email_promise_list: {
          requiredIf: requiredIf(function() {
            var filtro = this.createForm.plans_additional.filter(element => element == 2);
            return filtro.length > 0;
          }),
        },*/
      email_booking_list: {
        required
        /*requiredIf: requiredIf(function() {
            var filtro = this.createForm.plans_additional.filter(element => element == 1);
            return filtro.length > 0;
          }),*/
      },
      /*email_disengagement_list: {
          requiredIf: requiredIf(function() {
            var filtro = this.createForm.plans_additional.filter(element => element == 4);
            return filtro.length > 0;
          }),
        },*/
      chatbot_url: {
        requiredIf: requiredIf(function () {
          var filtro = this.createForm.plans_additional.filter(
            element => element == 3
          )
          return filtro.length > 0
        })
      },
      miniature: {
        required
      },
      /*portrait: {
          required
        },*/
      gallery: {
        required
      },
      orden: {
        numeric
      },
      crm_email: {
        email
      }
    },
    region: {
      required
    },
    province: {
      required
    },
    executive_name: {
      required
    },
    executive_phone: {
      required,
      numeric,
      minLength: minLength(9),
      maxLength: maxLength(9)
    },
    executive_email: {
      required,
      email
    },
    email_quotation: {
      required,
      email
    },
    /*email_promise: {
        required,
        email
      },*/
    email_booking: {
      required,
      email
    },
    /*email_disengagement: {
        required,
        email
      },*/
    //project_stage:{}
    exercise_area: false,
    green_area: false,
    bikers: false,
    games_accessibility: false,
    calisthenics_area: false,
    stores: false,
    events_room: false,
    clean_points: false,
    elevators: false,
    controlled_access: false,
    quincho: false,
    swimming_pool: false,
    hospital: false,
    subway_station: false
  },
  beforeMount () {
    var userrole = this.$PermissionHelper.getRole().toLowerCase()
    if (userrole == 'admin' || userrole == 'superadmin') {
      this.show_orden_input = true
    }
    this.permissions_visible = this.$PermissionHelper.checkPermissions(
      this.permissions_visible
    )
  },
  computed: {
    ...mapState('profile', ['locations']),
    ...mapGetters('profile', [
      'realStateAgencies',
      'projectStatus',
      'subsidyTypes',
      'projects',
      'plansAdditional',
      'projectStage'
    ]),
    passwordToggleIconApiKey () {
      return this.passwordFieldTypeApiKey === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconSecretKey () {
      return this.passwordFieldTypeSecretKey === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    miniatureErrors () {
      const errors = []
      if (!this.$v.createForm.miniature.$dirty) return errors
      !this.$v.createForm.miniature.required &&
        errors.push('La miniatura es obligatoria')
      return errors
    },
    /*portraitErrors() {
        const errors = []
        if (!this.$v.createForm.portrait.$dirty) return errors
        !this.$v.createForm.portrait.required && errors.push('La portada es obligatoria')
        return errors
      },*/
    galleryErrors () {
      const errors = []
      if (!this.$v.createForm.gallery.$dirty) return errors
      !this.$v.createForm.gallery.required &&
        errors.push('La galería es obligatoria')
      return errors
    },
    executive_name_listErrors () {
      const errors = []
      if (!this.$v.createForm.executive_name_list.$dirty) return errors
      !this.$v.createForm.executive_name_list.required &&
        errors.push('Debes ingresar al menos un nombre')
      return errors
    },
    executive_phone_listErrors () {
      const errors = []
      if (!this.$v.createForm.executive_phone_list.$dirty) return errors
      !this.$v.createForm.executive_phone_list.required &&
        errors.push('Debes ingresar al menos un teléfono')
      return errors
    },
    executive_email_listErrors () {
      const errors = []
      if (!this.$v.createForm.executive_email_list.$dirty) return errors
      !this.$v.createForm.executive_email_list.required &&
        errors.push('Debes ingresar al menos un correo')
      return errors
    },
    email_query_listErrors () {
      const errors = []
      if (!this.$v.createForm.email_quotation_list.$dirty) return errors
      !this.$v.createForm.email_quotation_list.required &&
        errors.push('Debes ingresar al menos un correo')
      return errors
    },
    email_quotation_listErrors () {
      const errors = []
      if (!this.$v.createForm.email_quotation_list.$dirty) return errors
      !this.$v.createForm.email_quotation_list.required &&
        errors.push('Debes ingresar al menos un correo')
      return errors
    },
    /*email_promise_listErrors() {
        const errors = []
        if (!this.$v.createForm.email_promise_list.$dirty) return errors
        !this.$v.createForm.email_promise_list.required && errors.push('Debes ingresar al menos un correo')
        return errors
      },*/
    email_booking_listErrors () {
      const errors = []
      if (!this.$v.createForm.email_promise_list.$dirty) return errors
      !this.$v.createForm.email_promise_list.required &&
        errors.push('Debes ingresar al menos un correo')
      return errors
    },
    /*email_disengagement_listErrors() {
        const errors = []
        if (!this.$v.createForm.email_disengagement_list.$dirty) return errors
        !this.$v.createForm.email_disengagement_list.required && errors.push('Debes ingresar al menos un correo')
        return errors
      },*/
    project_nameErrors () {
      const errors = []
      if (!this.$v.createForm.project_name.$dirty) return errors
      !this.$v.createForm.project_name.required &&
        errors.push('El nombre es obligatorio')
      return errors
    },
    property_typeErrors () {
      const errors = []
      if (!this.$v.createForm.property_type.$dirty) return errors
      !this.$v.createForm.property_type.required &&
        errors.push('Selecciona un tipo de propiedad')
      return errors
    },
    subsidy_typeErrors () {
      const errors = []
      if (!this.$v.createForm.subsidy_type.$dirty) return errors
      !this.$v.createForm.subsidy_type.required &&
        errors.push('Selecciona un tipo de subsidio')
      return errors
    },
    /*construction_stageErrors () {
        const errors = []
        if (!this.$v.createForm.project_status_id.$dirty) return errors
        !this.$v.createForm.project_status_id.required && errors.push('Selecciona una etapa')
        return errors
      },*/
    real_state_agencyErrors () {
      const errors = []
      if (!this.$v.createForm.real_state_agency.$dirty) return errors
      !this.$v.createForm.real_state_agency.required &&
        errors.push('Selecciona una inmobiliaria')
      return errors
    },
    /*quotation_validityErrors () {
        const errors = []
        if (!this.$v.createForm.quotation_validity.$dirty) return errors
        !this.$v.createForm.quotation_validity.required && errors.push('La vigencia es obligatoria')
        !this.$v.createForm.quotation_validity.numeric && errors.push('Solo números')
        return errors
      },*/
    /*reserve_validityErrors () {
        const errors = []
        if (!this.$v.createForm.reserve_validity.$dirty) return errors
        !this.$v.createForm.reserve_validity.required && errors.push('La vigencia es obligatoria')
        !this.$v.createForm.reserve_validity.numeric && errors.push('Solo números')
        return errors
      },*/
    price_fromErrors () {
      const errors = []
      if (!this.$v.createForm.price_from.$dirty) return errors
      !this.$v.createForm.price_from.required &&
        errors.push('El precio es obligatorio')
      return errors
    },
    bedrooms_fromErrors () {
      const errors = []
      if (!this.$v.createForm.bedrooms_from.$dirty) return errors
      !this.$v.createForm.bedrooms_from.required &&
        errors.push('Los dormitorios son obligatorios')
      return errors
    },
    /*bathrooms_fromErrors () {
        const errors = []
        if (!this.$v.createForm.bathrooms_from.$dirty) return errors
        !this.$v.createForm.bathrooms_from.required && errors.push('Los baños son obligatorios')
        return errors
      },*/
    area_fromErrors () {
      const errors = []
      if (!this.$v.createForm.area_from.$dirty) return errors
      !this.$v.createForm.area_from.required &&
        errors.push('El área es obligatoria')
      return errors
    },
    regionErrors () {
      const errors = []
      if (!this.$v.createForm.region.$dirty) return errors
      !this.$v.createForm.region.required &&
        errors.push('La región es obligatoria')
      return errors
    },
    provinceErrors () {
      const errors = []
      if (!this.$v.createForm.province.$dirty) return errors
      !this.$v.createForm.province.required &&
        errors.push('La provincia es obligatoria')
      return errors
    },
    communeErrors () {
      const errors = []
      if (!this.$v.createForm.commune.$dirty) return errors
      !this.$v.createForm.commune.required &&
        errors.push('La comuna es obligatoria')
      return errors
    },
    addressErrors () {
      const errors = []
      if (!this.$v.createForm.address.$dirty) return errors
      !this.$v.createForm.address.required &&
        errors.push('La dirección es obligatoria')
      return errors
    },
    latitudeErrors () {
      const errors = []
      if (!this.$v.createForm.latitude.$dirty) return errors
      !this.$v.createForm.latitude.required && errors.push('Campo obligatorio')
      !this.$v.createForm.latitude.decimal &&
        errors.push('Solo valores numéricos')
      return errors
    },
    longitudeErrors () {
      const errors = []
      if (!this.$v.createForm.longitude.$dirty) return errors
      !this.$v.createForm.longitude.required && errors.push('Campo obligatorio')
      !this.$v.createForm.longitude.decimal &&
        errors.push('Solo valores numéricos')
      return errors
    },
    /*reserve_amountErrors () {
        const errors = []
        if (!this.$v.createForm.reserve_amount.$dirty) return errors
        !this.$v.createForm.reserve_amount.required && errors.push('El monto es obligatorio')
        !this.$v.createForm.reserve_amount.numeric && errors.push('Solo números')
        return errors
      },*/
    /*reserve_typeErrors () {
        const errors = []
        if (!this.$v.createForm.reserve_type.$dirty) return errors
        !this.$v.createForm.reserve_type.required && errors.push('Debes seleccionar una opción')
        return errors
      },*/
    /*promises_typeErrors () {
        const errors = []
        if (!this.$v.createForm.promises_type.$dirty) return errors
        !this.$v.createForm.promises_type.required && errors.push('Debes seleccionar una opción')
        return errors
      },*/
    /*money_typeErrors () {
        const errors = []
        if (!this.$v.createForm.money_type.$dirty) return errors
        !this.$v.createForm.money_type.required && errors.push('Debes seleccionar una opción')
        return errors
      },*/
    executive_nameErrors () {
      const errors = []
      if (!this.$v.executive_name.$dirty) return errors
      !this.$v.executive_name.required && errors.push('Ingresa un nombre')
      return errors
    },
    executive_phoneErrors () {
      const errors = []
      if (!this.$v.executive_phone.$dirty) return errors
      !this.$v.executive_phone.required && errors.push('Ingresa un teléfono')
      !this.$v.executive_phone.numeric && errors.push('Solo números')
      !this.$v.executive_phone.minLength &&
        errors.push('Debes ingresar 9 dígitos')
      !this.$v.executive_phone.maxLength &&
        errors.push('Debes ingresar 9 dígitos')
      return errors
    },
    executive_emailErrors () {
      const errors = []
      if (!this.$v.executive_email.$dirty) return errors
      !this.$v.executive_email.required && errors.push('Ingresa un email')
      !this.$v.executive_email.email &&
        errors.push('Formato de email incorrecto')
      return errors
    },
    email_queryErrors () {
      const errors = []
      if (!this.$v.email_query.$dirty) return errors
      !this.$v.email_query.required && errors.push('Ingresa un email')
      !this.$v.email_query.email && errors.push('Formato de email incorrecto')
      return errors
    },
    email_quotationErrors () {
      const errors = []
      if (!this.$v.email_quotation.$dirty) return errors
      !this.$v.email_quotation.required && errors.push('Ingresa un email')
      !this.$v.email_quotation.email &&
        errors.push('Formato de email incorrecto')
      return errors
    },
    /*email_promiseErrors () {
        const errors = []
        if (!this.$v.email_promise.$dirty) return errors
        !this.$v.email_promise.required && errors.push('Ingresa un email')
        !this.$v.email_promise.email && errors.push('Formato de email incorrecto')
        return errors
      },*/
    email_bookingErrors () {
      const errors = []
      if (!this.$v.email_booking.$dirty) return errors
      !this.$v.email_booking.required && errors.push('Ingresa un email')
      !this.$v.email_booking.email && errors.push('Formato de email incorrecto')
      return errors
    },
    /*email_disengagementErrors () {
        const errors = []
        if (!this.$v.email_disengagement.$dirty) return errors
        !this.$v.email_disengagement.required && errors.push('Ingresa un email')
        !this.$v.email_disengagement.email && errors.push('Formato de email incorrecto')
        return errors
      },*/
    brochureErrors () {
      const errors = []
      if (!this.$v.createForm.brochure.$dirty) return errors
      !this.$v.createForm.brochure.url &&
        errors.push('Debes ingresar una URL válida')
      return errors
    },
    nube360Errors () {
      const errors = []
      if (!this.$v.createForm.nube360.$dirty) return errors
      !this.$v.createForm.nube360.url &&
        errors.push('Debes ingresar una URL válida')
      return errors
    },
    tour360Errors () {
      const errors = []
      if (!this.$v.createForm.tour360.$dirty) return errors
      !this.$v.createForm.tour360.url &&
        errors.push('Debes ingresar una URL válida')
      return errors
    },
    ordenErrors () {
      const errors = []
      if (!this.$v.createForm.orden.$dirty) return errors
      !this.$v.createForm.orden.numeric &&
        errors.push('Formato de orden incorrecto')
      return errors
    },
    crm_email_listErrors () {
      const errors = []
      if (!this.$v.createForm.crm_email.$dirty) return errors
      !this.$v.createForm.crm_email.email &&
        errors.push('Debes ingresar un correo valido')
      return errors
    },
    chatbotURLErrors () {
      const errors = []
      if (!this.$v.createForm.chatbot_url.$dirty) return errors
      //!this.$v.createForm.chatbot_url.required && errors.push('La URL es obligatoria')
      return errors
    }
  },
  methods: {
    ...mapActions('projects', [
      'createProjects',
      'exportDemoProperties',
      'checkPropertiesExcel',
      'savePropertiesExcel'
    ]),
    ready () {
      gmaps().then(maps => {
        this.autocomplete = new maps.places.Autocomplete(
          this.$refs.autocomplete
        )
        this.autocomplete.addListener('place_changed', this.updateAdress)
      })
    },
    updateAdress () {
      const place = this.autocomplete.getPlace()
      // Set end point to selected address
      if (place.geometry) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()

        this.createForm.address = place.name

        this.mapOptions.center = { lat, lng }
        this.mapOptions.zoom = 15
        this.markerOptions.position = { lat, lng }

        this.updatePosition({ lat, lng })
      }
    },
    modalClosed () {
      this.modalContact = {
        name: '',
        phone: '',
        email: '',
        image: '',
        isnew: '1'
      }
      this.gallery_contacts_modal = []
      this.$v.modalContact.$reset()
    },
    addContactOrder () {
      this.modalContact = {
        name: '',
        phone: '',
        email: '',
        image: undefined,
        realIndex: '-1',
        isnew: '1'
      }
      this.$refs['modalContacts'].show()
    },
    saveContactOrder () {
      let nombres = this.createForm.executive_name_list.filter(
        word => word == this.modalContact.name
      )
      if (nombres.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No puedes agregar el mismo nombre',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
        return false
      }
      let phones = this.createForm.executive_phone_list.filter(
        word => word == this.modalContact.phone
      )
      if (phones.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No puedes agregar el mismo telefono',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
        return false
      }
      let emails = this.createForm.executive_email_list.filter(
        word => word == this.modalContact.email
      )
      if (emails.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No puedes agregar el mismo email',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
        return false
      }

      this.modalContact.isnew = '0'
      this.modalContact.realIndex = this.createForm.executive_email_list.length

      this.contacts_orders.push(JSON.parse(JSON.stringify(this.modalContact)))
      this.createForm.executive_name_list.push(this.modalContact.name)
      this.createForm.executive_phone_list.push(this.modalContact.phone)
      this.createForm.executive_email_list.push(this.modalContact.email)
      if (this.modalContact.image != '') {
        this.createForm.gallery_contacts.push(this.modalContact.image)
      }
      this.gallery_contacts_modal = []

      this.modalContact = {
        name: '',
        phone: '',
        email: '',
        image: '',
        realIndex: '-1',
        isnew: '1'
      }
      this.$refs['modalContacts'].hide()
    },
    editContactOrderModal () {
      var indexElemento = this.modalContact.arrayIndex

      var executive_name_withoutme = JSON.parse(
        JSON.stringify(this.createForm.executive_name_list)
      )
      executive_name_withoutme.splice(indexElemento, 1)

      var executive_email_list_withoutme = JSON.parse(
        JSON.stringify(this.createForm.executive_email_list)
      )
      executive_email_list_withoutme.splice(indexElemento, 1)

      var executive_phone_list_withoutme = JSON.parse(
        JSON.stringify(this.createForm.executive_phone_list)
      )
      executive_phone_list_withoutme.splice(indexElemento, 1)

      let nombres = executive_name_withoutme.filter(
        word => word == this.modalContact.name
      )
      if (nombres.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ya existe un contacto con el mismo nombre',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
        return false
      }
      let phones = executive_phone_list_withoutme.filter(
        (word, index) =>
          word == this.modalContact.phone &&
          index != this.modalContact.realIndex
      )
      if (phones.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ya existe un contacto con el mismo telefono',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
        return false
      }
      let emails = executive_email_list_withoutme.filter(
        word => word == this.modalContact.email
      )
      if (emails.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ya existe un contacto con el mismo email',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
        return false
      }
      this.contacts_orders[indexElemento] = JSON.parse(
        JSON.stringify(this.modalContact)
      )
      this.createForm.executive_name_list[indexElemento] =
        this.modalContact.name
      this.createForm.executive_phone_list[indexElemento] =
        this.modalContact.phone
      this.createForm.executive_email_list[indexElemento] =
        this.modalContact.email
      this.createForm.gallery_contacts[indexElemento] = this.modalContact.image

      this.$refs['modalContacts'].hide()
    },
    showModalEdit (editContactOrder, index) {
      this.modalContact = {
        name: editContactOrder.name,
        phone: editContactOrder.phone,
        email: editContactOrder.email,
        image: editContactOrder.image,
        realIndex: editContactOrder.realIndex,
        arrayIndex: index,
        isnew: editContactOrder.isnew
      }
      this.$v.modalContact.$touch()
      this.gallery_contacts_modal = [this.contacts_orders[index].image]
      this.$refs['modalContacts'].show()
    },
    deleteContactOrder (editContactOrder, index) {
      this.$swal({
        title: '¿Estás seguro que deseas eliminar este contacto?',
        text: 'La siguiente acción eliminará el contacto.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.realDeleteContactOrder(editContactOrder, index)
        }
      })
    },
    realDeleteContactOrder (editContactOrder, index) {
      //this._deleteGalleryContact(index,editContactOrder.image);
      this.contacts_orders.splice(index, 1)
      this.createForm.executive_name_list.splice(index, 1)
      this.createForm.executive_phone_list.splice(index, 1)
      this.createForm.executive_email_list.splice(index, 1)
    },
    _deleteGalleryContact (position, img) {
      if (!img.new) {
        this.delete_gallery_contacts.push(img.media_id)
      }
      this.editForm.gallery_contacts.splice(position, 1)
    },
    _deleteGalleryContactModal () {
      if (!this.modalContact.image.new) {
        if (this.modalContact.image.media_id) {
          this.delete_gallery_contacts.push(this.modalContact.image.media_id)
        }
      }
      this.modalContact.image = undefined
    },
    _previewFileGalleryContactModal (event) {
      if (event.target.files[0] != null) {
        for (let index = 0; index < event.target.files.length; index++) {
          if (
            event.target.files[index].type === 'image/jpeg' ||
            event.target.files[index].type === 'image/jpg' ||
            event.target.files[index].type === 'image/png'
          ) {
            let fileObj = {
              file: event.target.files[index],
              src: URL.createObjectURL(event.target.files[index]),
              new: true
            }
            this.gallery_contacts_modal = [fileObj]
            this.modalContact.image = {
              file: event.target.files[index],
              src: URL.createObjectURL(event.target.files[index]),
              new: true,
              full_url: fileObj.src,
              new: true
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          }
        }
      } else {
      }
    },
    _previewFileGalleryContact (event) {
      if (event.target.files[0] != null) {
        for (let index = 0; index < event.target.files.length; index++) {
          if (
            event.target.files[index].type === 'image/jpeg' ||
            event.target.files[index].type === 'image/jpg' ||
            event.target.files[index].type === 'image/png'
          ) {
            this.editForm.gallery_contacts.push({
              file: event.target.files[index],
              src: URL.createObjectURL(event.target.files[index]),
              new: true
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          }
        }
        this.gallery_contacts = []
      } else {
        this.gallery_contacts = []
        this.editForm.gallery_contacts = []
      }
    },
    togglePasswordVisibilityApiKey () {
      if (this.passwordFieldTypeApiKey === 'password') {
        this.passwordFieldTypeApiKey = 'text'
      } else {
        this.passwordFieldTypeApiKey = 'password'
      }
    },
    togglePasswordVisibilitySecretKey () {
      if (this.passwordFieldTypeSecretKey === 'password') {
        this.passwordFieldTypeSecretKey = 'text'
      } else {
        this.passwordFieldTypeSecretKey = 'password'
      }
    },
    checkRegion () {
      this.createForm.province = ''
      this.createForm.commune = ''
      if (this.createForm.region != '' && this.createForm.region != null) {
        this.provinces_list = this.locations.filter(locations => {
          return locations.id === this.createForm.region
        })
        this.provinces_list = this.provinces_list[0].provinces
      }
    },
    checkProvince () {
      this.createForm.commune = ''
      if (this.createForm.province != '' && this.createForm.province != null) {
        this.communes_list = this.provinces_list.filter(provinces => {
          return provinces.id === this.createForm.province
        })
        this.communes_list = this.communes_list[0].communes
      }
    },
    maxProjects (e) {
      if (e.length > 3) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Puedes seleccionar hasta 3 proyectos',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
        e.pop()
      }
    },
    async _exportDemoProperties () {
      await this.exportDemoProperties().catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No se ha podido descargar el archivo',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      })
    },
    async _createProjects () {
      this.$v.createForm.$touch()
      if (!this.$v.createForm.$invalid) {
        this.createForm.gallery.forEach(element => {
          this.createForm.gallery_saved.push(element.file)
        })

        this.createForm.gallery_contacts.forEach(element => {
          if (element != undefined) {
            this.createForm.gallery_contacts_saved.push(element.file)
          }
        })

        let indexs = []
        this.contacts_orders.forEach(element => {
          if (element.image != '' && element.image != null) {
            indexs.push(element.image.new === true ? '1' : '0')
          } else {
            indexs.push('0')
          }
        })

        this.createForm.executive_images = indexs

        this.overlayForm = true
        this.createProjects(this.createForm)
          .then(res => {
            if (this.createForm.crm_id === '') {
              this._savePropertiesExcel(res.data.createProject.id)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'El proyecto ha sido creado con éxito',
                  icon: 'ThumbsUpIcon',
                  variant: 'success'
                }
              })
              this.createForm = {
                type: '',
                project_name: '',
                orden: '',
                property_type: '',
                subsidy_type: '',
                construction_stage: '',
                real_state_agency: '',
                //quotation_validity: '',
                //reserve_validity: '',
                //promises_type: '',
                //legal_quote_text: '',
                crm_id: '',
                crm_zp: '',
                price_from: '',
                bedrooms_from: '',
                //bathrooms_from: '',
                area_from: '',
                //deliver_date: '',
                project_description: '',
                api_key: '',
                secret_key: '',
                address: '',
                region: '',
                province: '',
                commune: '',
                latitude: '',
                length: '',
                //reserve_amount: '',
                email_query_list: [],
                email_quotation_list: [],
                //email_promise_list:[],
                email_booking_list: [],
                //email_disengagement_list:[],
                executive_images: [],
                brochure: '',
                nube360: '',
                tour360: '',
                miniature: [],
                //portrait: [],
                gallery: [],
                gallery_saved: [],
                gallery_contacts_saved: [],
                excel: [],
                plans_additional: [],
                chatbot_url: ''
              }
              //this.portrait = []
              this.gallery = []
              this.executive_name = ''
              this.executive_phone = ''
              this.email_query = ''
              this.$v.createForm.$reset()
              this.$router.push({ name: 'projects' })
            }
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'El proyecto no ha podido ser creado',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          })
          .finally(() => {
            this.overlayForm = false
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campos con errores o sin rellenar',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      }
    },
    async _checkPropertiesExcel () {
      this.infoExcelProperty = []
      await this.checkPropertiesExcel(this.excel)
        .then(res => {
          if (!res.data.checkPropertiesExcel.status) {
            let dataId = [],
              dataMsg = []
            dataId = JSON.parse(res.data.checkPropertiesExcel.data)
            dataMsg = JSON.parse(res.data.checkPropertiesExcel.info)
            for (let x = 0; x < dataMsg.length; x++) {
              let identifier = ''
              let messages = []
              let empty = true
              identifier = dataId[x].Identificador
              dataMsg[x].forEach(msg => {
                if (msg != '') {
                  messages.push(msg)
                  empty = false
                }
              })
              this.infoExcelProperty.push({
                identifier: identifier,
                messages: messages,
                empty: empty
              })
            }
            this.$bvModal.show('excel-modal')
            this.excel = []
            this.createForm.excel = []
          } else {
            this.jsonExcel = res.data.checkPropertiesExcel.data.replace(
              /"/g,
              '|'
            )
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Se ha cargado el excel con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success'
              }
            })
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ha ocurrido un error al verificar el Excel',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        })
    },
    async _savePropertiesExcel (id) {
      let data = { project_id: id, jsonExcel: this.jsonExcel }

      if (
        this.jsonExcel == null ||
        this.jsonExcel == undefined ||
        this.jsonExcel == ''
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'El proyecto ha sido creado con éxito',
            icon: 'ThumbsUpIcon',
            variant: 'success'
          }
        })
        this.createForm = {
          project_name: '',
          orden: '',
          property_type: '',
          subsidy_type: '',
          //project_status_id: '',
          real_state_agency: '',
          //projects_related: [],
          //quotation_validity: '',
          //reserve_validity: '',
          //promises_type: '',
          crm_id: '',
          crm_zp: '',
          crm_email: '',
          price_from: '',
          bedrooms_from: '',
          //bathrooms_from: '',
          area_from: '',
          //deliver_date: '',
          project_description: '',
          address: '',
          region: '',
          province: '',
          commune: '',
          latitude: '',
          length: '',
          //reserve_amount: '',
          executive_name_list: [],
          executive_phone_list: [],
          executive_email_list: [],
          email_query_list: [],
          email_quotation_list: [],
          //email_promise_list:[],
          email_booking_list: [],
          brochure: '',
          nube360: '',
          tour360: '',
          miniature: [],
          //portrait: [],
          gallery: [],
          excel: [],
          //summary: '',
          chatbot_url: ''
        }
        //this.portrait = []
        this.gallery = []
        this.$v.createForm.$reset()
        this.$router.push({ name: 'projects' })
      } else {
        await this.savePropertiesExcel(data)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'El proyecto ha sido creado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success'
              }
            })
            this.createForm = {
              project_name: '',
              orden: '',
              property_type: '',
              subsidy_type: '',
              //project_status_id: '',
              real_state_agency: '',
              //projects_related: [],
              //quotation_validity: '',
              //reserve_validity: '',
              //promises_type: '',
              crm_id: '',
              crm_zp: '',
              crm_email: '',
              price_from: '',
              bedrooms_from: '',
              //bathrooms_from: '',
              area_from: '',
              //deliver_date: '',
              project_description: '',
              address: '',
              region: '',
              province: '',
              commune: '',
              latitude: '',
              length: '',
              //reserve_amount: '',
              executive_name_list: [],
              executive_phone_list: [],
              executive_email_list: [],
              email_query_list: [],
              email_quotation_list: [],
              //email_promise_list:[],
              email_booking_list: [],
              brochure: '',
              nube360: '',
              tour360: '',
              miniature: [],
              //portrait: [],
              gallery: [],
              excel: [],
              //summary: '',
              chatbot_url: ''
            }
            //this.portrait = []
            this.gallery = []
            this.$v.createForm.$reset()
            this.$router.push({ name: 'projects' })
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ha ocurrido un error',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          })
      }
    },
    _deleteMiniature () {
      this.createForm.miniature = ''
    },
    _previewFileMiniature (event) {
      if (event.target.files[0] != null) {
        if (
          event.target.files[0].type === 'image/jpeg' ||
          event.target.files[0].type === 'image/jpg' ||
          event.target.files[0].type === 'image/png'
        ) {
          this.miniature = []
          this.createForm.miniature = {
            file: event.target.files[0],
            src: URL.createObjectURL(event.target.files[0])
          }
        } else {
          this.createForm.miniature = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Formato de archivo incorrecto',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        }
      } else {
        this.createForm.miniature = ''
        this.miniature = []
      }
    },
    /*_deletePortrait() {
        this.createForm.portrait = ''
      },*/
    /*_previewFilePortrait(event) {
        if(event.target.files[0] != null){
          if(event.target.files[0].type==='image/jpeg' || event.target.files[0].type==='image/jpg' || event.target.files[0].type==='image/png'){
            this.portrait = []
            this.createForm.portrait = {file: event.target.files[0], src: URL.createObjectURL(event.target.files[0])}
          }else{
            this.createForm.portrait = ''
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }
        }else{
          this.createForm.portrait = ''
          this.portrait = []
        }
      },*/
    _deleteGallery (item) {
      this.createForm.gallery.splice(item, 1)
    },
    _deleteGalleryContact (item) {
      this.createForm.gallery_contacts.splice(item, 1)
    },
    _previewFileGalleryContact (event) {
      if (event.target.files[0] != null) {
        for (let index = 0; index < event.target.files.length; index++) {
          if (
            event.target.files[index].type === 'image/jpeg' ||
            event.target.files[index].type === 'image/jpg' ||
            event.target.files[index].type === 'image/png'
          ) {
            this.createForm.gallery_contacts.push({
              file: event.target.files[index],
              src: URL.createObjectURL(event.target.files[index])
            })
          } else {
            this.createForm.gallery_contacts.splice(index, 1)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          }
        }
        this.gallery_contacts = []
      } else {
        this.gallery_contacts = []
        this.createForm.gallery_contacts = []
      }
    },
    _previewFileGallery (event) {
      if (event.target.files[0] != null) {
        for (let index = 0; index < event.target.files.length; index++) {
          if (
            event.target.files[index].type === 'image/jpeg' ||
            event.target.files[index].type === 'image/jpg' ||
            event.target.files[index].type === 'image/png'
          ) {
            this.createForm.gallery.push({
              file: event.target.files[index],
              src: URL.createObjectURL(event.target.files[index])
            })
          } else {
            this.createForm.gallery.splice(index, 1)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Formato de archivo incorrecto',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          }
        }
        this.gallery = []
      } else {
        this.gallery = ''
        this.createForm.gallery = []
      }
    },
    _deleteExcel () {
      this.createForm.excel = []
    },
    _previewFileExcel (event) {
      if (
        event.target.files[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.createForm.excel = event.target.files[0]
        this.excel = event.target.files[0]
        this._checkPropertiesExcel()
      } else {
        this.createForm.excel = []
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Formato de archivo incorrecto',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      }
    },
    returnProjectList () {
      this.$router.push({ name: 'projects' })
    },
    _removeExecutive (item, type) {
      if (type === 'name') {
        this.createForm.executive_name_list.splice(
          this.createForm.executive_name_list.indexOf(item),
          1
        )
      }
      if (type === 'phone') {
        this.createForm.executive_phone_list.splice(
          this.createForm.executive_phone_list.indexOf(item),
          1
        )
      }
      if (type === 'email') {
        this.createForm.executive_email_list.splice(
          this.createForm.executive_email_list.indexOf(item),
          1
        )
      }
    },
    _addExecutive (type) {
      if (type === 'name' && !this.$v.executive_name.$error) {
        if (this.executive_name === '') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ingresa un nombre',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        } else {
          let exist = false
          for (
            let index = 0;
            index < this.createForm.executive_name_list.length;
            index++
          ) {
            if (
              this.executive_name === this.createForm.executive_name_list[index]
            ) {
              exist = true
            }
          }
          if (exist) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'No puedes agregar el mismo nombre',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          } else {
            this.createForm.executive_name_list.push(this.executive_name)
            this.executive_name = ''
            this.$v.executive_name.$reset()
          }
        }
      }
      if (type === 'phone' && !this.$v.executive_phone.$error) {
        if (this.executive_phone === '') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ingresa un teléefono',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        } else {
          let exist = false
          for (
            let index = 0;
            index < this.createForm.executive_phone_list.length;
            index++
          ) {
            if (
              this.executive_phone ===
              this.createForm.executive_phone_list[index]
            ) {
              exist = true
            }
          }
          if (exist) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'No puedes agregar el mismo teléfono',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          } else {
            this.createForm.executive_phone_list.push(this.executive_phone)
            this.executive_phone = ''
            this.$v.executive_phone.$reset()
          }
        }
      }
      if (type === 'email' && !this.$v.executive_email.$error) {
        if (this.executive_email === '') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ingresa un correo',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        } else {
          let exist = false
          for (
            let index = 0;
            index < this.createForm.executive_email_list.length;
            index++
          ) {
            if (
              this.executive_email ===
              this.createForm.executive_email_list[index]
            ) {
              exist = true
            }
          }
          if (exist) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'No puedes agregar el mismo correo',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          } else {
            this.createForm.executive_email_list.push(this.executive_email)
            this.executive_email = ''
            this.$v.executive_email.$reset()
          }
        }
      }
    },
    _removeEmail (item, type) {
      if (type === 'query') {
        this.createForm.email_query_list.splice(
          this.createForm.email_query_list.indexOf(item),
          1
        )
      }
      /*if(type === "promise"){
          this.createForm.email_promise_list.splice(this.createForm.email_promise_list.indexOf(item), 1)
        }*/
      if (type === 'booking') {
        this.createForm.email_booking_list.splice(
          this.createForm.email_booking_list.indexOf(item),
          1
        )
      }
      /*if(type === "disengagement"){
          this.createForm.email_disengagement_list.splice(this.createForm.email_disengagement_list.indexOf(item), 1)
        }*/
      if (type === 'quotation') {
        this.createForm.email_quotation_list.splice(
          this.createForm.email_quotation_list.indexOf(item),
          1
        )
      }
    },
    _addEmail (type) {
      /*if(type === "promise" && !this.$v.email_promise.$error){
          if(this.email_promise === ""){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ingresa un correo',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }else{
            let exist = false
            for (let index = 0; index < this.createForm.email_promise_list.length; index++) {
              if(this.email_promise === this.createForm.email_promise_list[index]){
                exist = true
              }
            }
            if(exist){
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'No puedes agregar el mismo correo',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            }else{
              this.createForm.email_promise_list.push(this.email_promise)
              this.email_promise = ""
              this.$v.email_promise.$reset()
            }
          }
        }*/
      if (type === 'booking' && !this.$v.email_booking.$error) {
        if (this.email_booking === '') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ingresa un correo',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        } else {
          let exist = false
          for (
            let index = 0;
            index < this.createForm.email_booking_list.length;
            index++
          ) {
            if (
              this.email_booking === this.createForm.email_booking_list[index]
            ) {
              exist = true
            }
          }
          if (exist) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'No puedes agregar el mismo correo',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          } else {
            this.createForm.email_booking_list.push(this.email_booking)
            this.email_booking = ''
            this.$v.email_booking.$reset()
          }
        }
      }
      /*if(type === "disengagement" && !this.$v.email_disengagement.$error){
          if(this.email_disengagement === ""){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ingresa un correo',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }else{
            let exist = false
            for (let index = 0; index < this.createForm.email_disengagement_list.length; index++) {
              if(this.email_disengagement === this.createForm.email_disengagement_list[index]){
                exist = true
              }
            }
            if(exist){
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'No puedes agregar el mismo correo',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
            }else{
              this.createForm.email_disengagement_list.push(this.email_disengagement)
              this.email_disengagement = ""
              this.$v.email_disengagement.$reset()
            }
          }
        }*/
      if (type === 'quotation' && !this.$v.email_quotation.$error) {
        if (this.email_quotation === '') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ingresa un correo',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        } else {
          let exist = false
          for (
            let index = 0;
            index < this.createForm.email_quotation_list.length;
            index++
          ) {
            if (
              this.email_quotation ===
              this.createForm.email_quotation_list[index]
            ) {
              exist = true
            }
          }
          if (exist) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'No puedes agregar el mismo correo',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            })
          } else {
            this.createForm.email_quotation_list.push(this.email_quotation)
            this.email_quotation = ''
            this.$v.email_quotation.$reset()
          }
        }
      }
    },
    _onHidden () {
      this.$refs.button.focus()
    },
    addMarker ($event) {
      if (this.markers.length === 0) {
        const position = $event.latLng.toJSON()
        this.createForm.latitude = position.lat
        this.createForm.longitude = position.lng
        this.mapOptions.center = position
        this.markers.push({ position })
      } else {
        this.removeMarker()
      }
    },
    removeMarker () {
      this.markers = []
    },
    updatePosition (position) {
      this.createForm.latitude = position.lat
      this.createForm.longitude = position.lng
    },
    isRutValid (rut) {
      return rutHelpers.rutValidate(rut)
    }
  }
}
</script>

<style>
.con-vs-popup .vs-popup {
  width: auto !important;
}
.ckeditor {
  height: 150px;
  width: 100%;
}
.ck-content {
  height: 100px;
}
.divInfo {
  border-radius: 10px;
}
.flex {
  display: flex;
  align-items: center;
}
.inputLabel {
  background-color: #e7e7e7;
  color: black;
  padding: 8px;
  border-radius: 10px;
}
.inputLabel:hover {
  background-color: #ea387f;
  color: white;
}
.preview {
  border-radius: 10px;
  height: 200px;
  width: auto;
  background-color: #e7e7e7;
  text-align: center;
  padding: 90px 0;
}
.image {
  border-radius: 10px;
  width: auto;
  height: 200px;
  object-fit: cover;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.container {
  position: relative;
}
.middle {
  transition: 0.5s ease;
  opaprovince: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.map {
  height: 425px;
}
div.danger-text {
  color: red;
}
</style>
