<template>
  <div id="projectsCreate">
    <div>
      <b-row>
        <b-col cols="12">
          <projects-form-create></projects-form-create>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import ProjectsFormCreate from './components/ProjectsFormCreate'

  export default{
    name: 'projectsCreate',
    components: {ProjectsFormCreate},
    data() {
      return {
      }
    },
  }
</script>